import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import "destyle.css";
import "@/styles/lp/common.scss";
import LP from "@/LP.vue";

const app = createApp(LP);

if (import.meta.env.MODE === "production") {
  const gtm = createGtm({ id: import.meta.env.VITE_GTM_ID });
  app.use(gtm);
}

document.addEventListener("DOMContentLoaded", () => {
  app.mount("#app");
});
